import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineHome, AiFillGithub, AiFillLinkedin } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import { MdWorkOutline } from 'react-icons/md'
import { RiContactsLine } from 'react-icons/ri'

// Change the function names and links
// to fit your portfolio topic.

function Navigation() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  }

  return (
    <nav>
        <div class="left-group">
          <div class="logo-bubble">dp</div>
        </div>

        {/* <div class="center-group">
          <NavLink exact to="/" activeClassName="active" className="nav-link"><AiOutlineHome/></NavLink>
          <NavLink to="../gallery" activeClassName="active" className="nav-link"><MdWorkOutline /></NavLink>
          <NavLink to="../contact" activeClassName="active" className="nav-link"><RiContactsLine /></NavLink>
        </div> */}

        <div class="center-group">
          <NavLink to="/" activeClassName="active" className="nav-link" onClick={() => window.open('https://github.com/dianna-SE', '_blank')} ><AiFillGithub/></NavLink>
          <NavLink to="/" activeClassName="active" className="nav-link" onClick={() => window.open('https://www.linkedin.com/in/diannapham-se/', '_blank')}><AiFillLinkedin/></NavLink>
        </div>

         <div>
            <div className="menu-icon" onClick={() => setMenuOpen(true)}>
                <AiOutlineMenu />
            </div>

            {isMenuOpen && (
              
                <div className="sliding-menu">
                    <button onClick={closeMenu}><GrClose /></button>
                    <NavLink to="/" onClick={() => window.open('https://github.com/dianna-SE', '_blank')} >GitHub</NavLink>
                    <NavLink to="/" onClick={() => window.open('https://www.linkedin.com/in/diannapham-se/', '_blank')}>LinkedIn</NavLink>
                    {/* <NavLink to="/" onClick={closeMenu}>Home</NavLink>
                    <NavLink to="/gallery" onClick={closeMenu}>Works</NavLink>
                    <NavLink to="/contact" onClick={closeMenu}>Contact</NavLink> */}
                </div>
            )}

        </div>
    </nav>

    
  );
}

export default Navigation;
