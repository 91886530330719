import React, { useState } from 'react';

export const ReviewPage = () => {


return (
  <>
    <h2>Contact</h2>
    <p>If you're interested in my work, don't hesitate to reach out to me!</p>

    <article className="article-contact">

    </article>
  </>
);
};

export default ReviewPage;