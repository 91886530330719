import React, { useState } from 'react';

export const ContactPage = () => {

  const [formData, setFormData] = useState({});

const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Sending form data:", formData);

    // Make a POST request to the '/review' endpoint with the form data
    fetch('http://localhost:8000/review', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
})
    .then((response) => {
      if (response.ok && response.headers.get("content-type")?.includes("application/json")) {
        console.log("RESPONSE OK")  
        return response.json();
      }
      console.log("RESPONSE ERROR") 
      throw new Error(`Unexpected response: ${response.statusText}`);
  })
    .then((data) => {
        // Handle the response from the server
        console.log("SUCCESS!!!")
        console.log('Success:', data);
    })
    .catch((error) => {
        console.log("ERROR!!!")
        console.error('Error:', error);
    });
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData(prevState => ({
      ...prevState,
      [name]: value
  }));
};

return (
  <>
    <h2>Contact</h2>
    <p>If you're interested in my work, don't hesitate to reach out to me!</p>

    <article className="article-contact">
      <form onSubmit={handleSubmit}>
        <fieldset className="contact-fieldset">
          <legend>Personal Information</legend>

          <label htmlFor="firstName">First Name
            <input 
              type="text" 
              name="firstName" 
              id="firstName" 
              size="30" 
              maxLength="30" 
              required 
              placeholder="First name" 
              autoFocus 
              onChange={handleChange}
            />
          </label>

          <label htmlFor="lastName">Last Name
            <input 
              type="text" 
              name="lastName" 
              id="lastName" 
              size="30" 
              maxLength="30" 
              required 
              placeholder="Last name" 
              onChange={handleChange}
            />
          </label>

          <label htmlFor="email">Email
            <input 
              type="email" 
              name="eAddress" 
              id="email" 
              size="30" 
              maxLength="50" 
              required 
              pattern="[^ @]+@[^ @]+.[a-z]+" 
              placeholder="E-mail address"
              onChange={handleChange} 
            />
          </label>

          <label htmlFor="comments">What else would you like to tell us?</label>
          <textarea 
            name="comments" 
            id="comments" 
            minLength="3" 
            maxLength="250" 
            required 
            placeholder="Please add any comments here." 
            onChange={handleChange}
          ></textarea>
          
          <button type="submit">Submit review</button>
        </fieldset>
      </form>
    </article>
  </>
);
};

export default ContactPage;